// Italian
export default{
    form: {
        startSurveyButton: "Iniziamo",
        nextButton: "Avanti",
        submitAnswerButton: "Invia risposta",
        continueButton: "Continua",
        finishButton: "Fine",
        headerPercentageCompletion: 'completato',
        headerQuestionsCompleted: 'domande',
        headerDefaultErrorMessage: 'Ops. Qualcosa è andato storto.',
        brandingPoweredBy: 'Offerto da',
        brandingPromoWhySurvey: 'Perché fare un sondaggio quando puoi usare <b>{product}</b>?',
        brandingPromoGetStarted: 'Inizia oggi!',
        submissionPending: "Invio del tuo voiceform in corso. Per favore non chiudere il browser.",
        submissionCanCloseWindow: "Il tuo voiceform è stato inviato con successo. Ora puoi chiudere la finestra 👍",
        mediaAttachmentOverlayCloseButton: "Chiudi",
        expiredFormHeader: "Grazie per la visita!",
        expiredFormMessage: "Sembra che questo sondaggio sia scaduto. Contatta il creatore del voiceform per avvisarlo.",
        notPublishedFormHeader: "Il voiceform non è pubblicato!",
        notPublishedFormMessage: "Sembra che questo sondaggio non sia stato pubblicato. Contatta il creatore del voiceform per avvisarlo.",
        notPublishedFormRetryButton: "Riprova",
        errorFormHeader: "Ops!",
        errorFormMessage: "Qualcosa potrebbe essere andato storto. Prova più tardi.",
        errorFormRetryButton: "Riprova",
        emptyFormHeader: "Grazie per la visita!",
        emptyFormMessage: "Ops! Sembra che questo modulo sia attualmente vuoto, senza domande o campi da compilare. Ci scusiamo per l'inconveniente. Per risolvere il problema, contatta il creatore del modulo. Grazie per la tua comprensione e collaborazione!",

        restoreFormHeader: "Sembra che tu abbia un modulo in corso!",
        restoreFormMessage: "Vuoi riprendere da dove avevi interrotto?",
        restoreFormButton: "Continua modulo",
        restoreFormButtonNew: "Ricomincia da capo",

        audioPermissionHeader: "Vuoi rispondere con la voce?",
        audioPermissionMessage: "Parlare renderà la risposta veloce e facile.",
        audioPermissionAcceptButton: "Sì, lo voglio",
        audioPermissionDenyButton: "No, grazie",
        audioPermissionErrorHeader: "Microfono non disponibile",
        audioPermissionErrorMessage: "Controlla le autorizzazioni del browser per abilitare l'accesso al microfono.",
        audioPermissionErrorButton: "OK",
        audioRecorderFailedUpload: "Caricamento della registrazione fallito",
        silenceDetectorError: "Non riesco a sentirti. Controlla le impostazioni del microfono. Suggerimento: potrebbe essere il microfono esterno.",
        audioRecorderButtonRecord: "Registra",
        audioRecorderButtonRecordMore: "Registra di più",
        voiceResponseSelectionMessage: "Scegli come vuoi rispondere...",
        voiceResponseSelectionOrDivider: "o",
        voiceResponseTextInputPlaceholder: "Digita la risposta",
        voiceResponseTextInputCharacterCounterMinMessage: "Inserisci almeno {min} caratteri",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} caratteri (minimo {min})",
        voiceResponseTextInputCharacterValidationMessage: "{count} caratteri (minimo {min})",

        // checkbox
        checkboxValidationTooFew: "Devi selezionare almeno {min} opzione/i",
        checkboxValidationTooMany: "Puoi selezionare al massimo {max} opzione/i",
        checkboxNoneOfTheAboveOption: "Nessuna delle precedenti",
        checkboxInvalidAnswer: "Risposta non valida.",

        // datepicker
        datePickerPlaceholder: "Seleziona una data",

        // dropdown
        // email
        emailLabel: "Email",
        emailInvalid: "Email non valida.",
        // file-upload
        fileUploadPluginNameCamera: "Fotocamera",
        fileUploadPluginNameCameraVideo: "Registra video",
        fileUploadPluginScreenCast: "Condivisione schermo",
        fileUploadVideoImportFilesDefault: 'Registra o carica un video tramite:',
        fileUploadVideoImportFilesLocalFilesDisabled: "Seleziona un'opzione di registrazione video:",
        fileUploadVideoImportFilesNoCamera: 'Premi un pulsante per registrare un video dello schermo',
        fileUploadVideoImportFilesNoScreenCast: 'Premi un pulsante per registrare un video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Nessuna opzione disponibile per registrare un video',

        // matrix
        matrixValidationMessage: "La risposta è valida.",
        matrixRow: "Riga",
        matrixColumn: "Colonna",
        matrixRowRequired: "La riga {rowTitle} è obbligatoria.",
        matrixRadioGroup: "Gruppo radio",
        matrixCheckboxGroup: "Gruppo checkbox",
        matrixGroupRequired: "Per la riga {rowTitle}. {type} {groupTitle} è obbligatorio.",
        matrixColumnRequired: "Per la riga {rowTitle}. La colonna {columnTitle} è obbligatoria.",
        matrixColumnInvalid: "Per la riga {rowTitle}. La colonna {columnTitle} non è valida.",
        matrixRequired: "Obbligatorio.",
        matrixNumericMustBeNumber: "Deve essere un numero.",
        matrixNumericMustBeGreaterThenMin: "Deve essere maggiore di {min}.",
        matrixNumericMustBeLessThenMax: "Deve essere minore di {max}.",
        matrixNumericMustBeInteger: "Deve essere un numero intero.",
        matrixNumericInvalidNumber: "Numero non valido.",

        // name
        nameLabel: "Nome",
        nameInvalid: "Specifica nome e cognome.",

        // nps
        npsNotLikely: "Per niente probabile",
        npsExtremelyLikely: "Molto probabile",

        // numeric input
        numericInputRequired: "Obbligatorio.",
        numericInputMustBeNumber: "Deve essere un numero.",
        numericInputMustBeGreaterThenMin: "Deve essere maggiore di {min}.",
        numericInputMustBeLessThenMax: "Deve essere minore di {max}.",
        numericInputMustBeInteger: "Deve essere un numero intero.",
        numericInputInvalidNumber: "Numero non valido.",
        numericInputPlaceholder: "Inserisci un numero",

        // phone
        phoneInvalid: "Numero di telefono non valido.",
        phoneCountrySelectorLabel: 'Prefisso paese',
        phoneCountrySelectorError: 'Seleziona il paese',
        phoneNumberLabel: 'Numero di telefono',
        phoneExample: 'Esempio:',

        // boolean
        booleanYesLabel: "Sì",
        booleanNoLabel: "No",

        //questionStep
        questionStepAudioQuestionLabel: "Domanda audio",

        // errors
        invalidPhoneNumber: "{phone} è un numero di telefono non valido.",
        invalidEmail: "{email} è un indirizzo email non valido.",
        questionIsRequired: "La domanda è obbligatoria.",
        answerIsNotValid: "La risposta non è valida.",
        unfinishedProbingDialogError: "Completa il dialogo.",
        cannotResumePartialResponse: "Impossibile riprendere la risposta parziale.",
        failedToSubmitForm: "Impossibile inviare il modulo. Verifica la connessione a Internet e riprova.",

        //language picker
        searchLanguage: "Cerca lingua",
    }
}
